// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-casestudies-index-js": () => import("./../../../src/pages/casestudies/index.js" /* webpackChunkName: "component---src-pages-casestudies-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-produkt-strategie-js": () => import("./../../../src/pages/produkt-strategie.js" /* webpackChunkName: "component---src-pages-produkt-strategie-js" */),
  "component---src-pages-produktion-js": () => import("./../../../src/pages/produktion.js" /* webpackChunkName: "component---src-pages-produktion-js" */),
  "component---src-pages-user-experience-design-ux-js": () => import("./../../../src/pages/user-experience-design-ux.js" /* webpackChunkName: "component---src-pages-user-experience-design-ux-js" */),
  "component---src-templates-text-template-js": () => import("./../../../src/templates/text-template.js" /* webpackChunkName: "component---src-templates-text-template-js" */)
}

